<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">报名管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">报考进度管理</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 10px">
            <div title="学员姓名" class="searchboxItem ci-full">
              <span class="itemLabel">学员姓名:</span>
              <el-input v-model="retrievalData.userName" placeholder="请输入学员姓名" type="text" clearable size="small" />
            </div>
            <div title="手机号" class="searchboxItem ci-full">
              <span class="itemLabel">手机号:</span>
              <el-input v-model="retrievalData.mobile" placeholder="请输入手机号" type="text" clearable size="small" />
            </div>
            <div title="身份证号" class="searchboxItem ci-full">
              <span class="itemLabel">身份证号:</span>
              <el-input v-model="retrievalData.idcard" placeholder="请输入身份证号" type="text" clearable size="small" />
            </div>
            <div title="审核结果" class="searchboxItem ci-full">
              <span class="itemLabel">审核结果:</span>
              <el-select v-model="retrievalData.auditState" placeholder="请选择审核结果" size="small" clearable>
                <el-option v-for="item in auditStateList" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </div>
          </div>
          <div class="searchbox" style="margin-bottom: 10px">
            <div title="报考进度" class="searchboxItem ci-full">
              <span class="itemLabel">报考进度:</span>
              <el-select v-model="retrievalData.applyState" placeholder="请选择报考进度" size="small" clearable>
                <el-option v-for="item in applyStateList" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </div>
            <div title="培训工种" class="searchboxItem ci-full">
              <span class="itemLabel">培训工种:</span>
              <el-select v-model="retrievalData.occupationId" placeholder="请选择培训工种" clearable size="small">
                <el-option v-for="item in occupationList" :key="item.occupationId" :label="item.occupationName"
                  :value="item.occupationId">
                  <span style="float: left">{{ item.occupationName }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{ item.trainLevel }}</span>
                </el-option>
              </el-select>
            </div>
            <div title="招生老师" class="searchboxItem ci-full">
              <span class="itemLabel">招生老师:</span>
              <el-select v-model="retrievalData.teacherId" placeholder="请选择招生老师" clearable size="small">
                <el-option v-for="item in teacherIdList" :key="item.channelId" :label="item.contactUser"
                  :value="item.channelId">
                </el-option>
              </el-select>
            </div>
            <div title="缴费状态" class="searchboxItem ci-full">
              <span class="itemLabel">缴费状态:</span>
              <el-select v-model="retrievalData.paymentState" placeholder="请选择缴费状态" size="small" clearable>
                <el-option v-for="item in paymentStateList" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </div>
          </div>
          <div class="searchbox">
            <div title="报名日期" class="searchboxItem">
              <span class="itemLabel">报名日期:</span>
              <el-date-picker v-model="retrievalData.registrationTime" size="small" type="daterange" style="width: 5rem"
                range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value="yyyy-MM-dd"
                value-format="yyyy-MM-dd" />
            </div>
            <el-button class="bgc-bv" style="margin: 0 10px" round @click="getData()">查询</el-button>
            <el-button class="bgc-bv" style="margin: 0 10px" round @click="setAddAndEdit()">进度设置</el-button>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
              style="width: 100%" :header-cell-style="tableHeader" stripe @selection-change="handleSelectionChange"
              :row-key="(row) => row.projectApplyId">
              <el-table-column type="selection" :reserve-selection="true" width="55" />
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" fixed />
              <el-table-column label="姓名" align="left" show-overflow-tooltip prop="userName" width="100" />
              <el-table-column label="联系方式" align="left" show-overflow-tooltip prop="mobile" width="120" />
              <el-table-column label="培训工种" align="left" show-overflow-tooltip prop="occupationName" min-width="150" />
              <el-table-column label="培训等级" align="left" show-overflow-tooltip prop="trainLevel" min-width="150">
                <template slot-scope="scope">
                  {{ $setDictionary("ET_TRAIN_LEVEL", scope.row.trainLevel) }}
                </template>
              </el-table-column>
              <el-table-column label="报名方式" align="left" show-overflow-tooltip prop="joinType" width="120">
                <template slot-scope="scope">
                  {{ $setDictionary("CT_JOIN_TYPE", scope.row.joinType) }}
                </template>
              </el-table-column>
              <el-table-column label="缴费状态" align="left" show-overflow-tooltip prop="paymentState" width="120">
                <template slot-scope="scope">
                  {{ $setDictionary("CT_USER_PAYMENT_STATE", scope.row.paymentState) }}
                </template>
              </el-table-column>
              <el-table-column label="报考进度" align="left" show-overflow-tooltip prop="applyState" width="120">
                <template slot-scope="scope">
                  {{ $setDictionary("CT_USER_APPLY_STATE", scope.row.applyState) }}
                </template>
              </el-table-column>
              <el-table-column label="招生老师" align="left" show-overflow-tooltip prop="teacherName" min-width="120">
                <template slot-scope="scope">
                  {{ scope.row.teacherName ? scope.row.teacherName : '自招' }}
                </template>
              </el-table-column>
              <el-table-column label="报名时间" align="left" show-overflow-tooltip prop="createTime" width="160">
                <template slot-scope="scope">
                  {{ scope.row.createTime | moment }}
                </template>
              </el-table-column>
              <el-table-column label="更新时间" align="left" show-overflow-tooltip prop="applyTime" width="160">
                <template slot-scope="scope">
                  {{ scope.row.applyTime | moment }}
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" width="100px" fixed="right">
                <div slot-scope="scope">
                  <el-button type="text" size="mini" style="padding: 0 5px"
                    @click="setAddAndEdit(scope.row)">进度设置</el-button>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
    <el-dialog title="进度设置" :visible.sync="addAndEditData.dialogVisible" width="500px" :destroy-on-close="true">
      <el-form ref="addAndEditData" class="addAndEditData" :model="addAndEditData" :rules="addAndEditDataRules"
        label-width="120px" size="small" :before-close="cancelData">
        <el-form-item label="报考进度" prop="applyState">
          <el-select v-model="addAndEditData.applyState" placeholder="请选择报考进度" size="small" clearable>
            <el-option v-for="item in applyStateList" :key="item.value" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" class="bgc-bv" size="small" @click="cancelData">取消</el-button>
        <el-button type="primary" class="bgc-bv" size="small" @click="saveData">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
export default {
  name: "compulsoryCourses",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      // 检索数据
      retrievalData: {
        userName: "", // 学员姓名
        mobile: "", // 手机号
        idcard: "", // 身份证号
        auditState: "", // 审核结果
        applyState: "", // 报考进度
        occupationId: "", // 培训工种
        teacherId: "", // 招生老师
        paymentState: "", // 缴费状态
        registrationTime: [], // 报名时间
      },
      // 审核结果 - 下拉数据
      auditStateList: [],
      // 报考进度 - 下拉数据
      applyStateList: [],
      // 培训工种 - 下拉数据
      occupationList: [],
      // 招生老师 - 下拉数据
      teacherIdList: [],
      // 缴费状态 - 下拉数据
      paymentStateList: [],
      // 列表勾选数据
      multipleSelection: [],
      // 新增&编辑课程 - 弹框
      addAndEditData: {
        dialogVisible: false, // 弹框显示
        projectApplyIdList: [], // 学员申请id集合
        applyState: "", // 报考进度
      },
      // 新增&编辑课程 - 数据校验
      addAndEditDataRules: {
        applyState: [
          { required: true, message: "请选择报考进度", trigger: "change" },
        ],
      },
    };
  },
  watch: {},
  created() {
    this.getInvoice_type();
    this.getCtChannelSelectList();
    this.searchCompOccupation();
  },
  computed: {},
  mounted() { },
  methods: {
    // 获取 - 学员信息 - 下拉数据
    superUserSelect(e) {
      this.$post(
        "/biz/user/superUserSelect",
        {
          pageNum: 1, //每次都只要第一页
          pageSize: 5, //根据次参数控制显示的数据行数，尽量不出现滚动条
          userName: this.userDatas.seaUserName,
          idcard: this.userDatas.seaUserIdcard,
          mobile: this.userDatas.seaUserMobile,
          projectId: this.userDatas.projectId,
        },
        3000,
        false
      ).then((res) => {
        if (res.data.list.length === 0) {
          this.userDatas.userList = [{}];
        } else {
          this.userDatas.userList = res.data.list;
        }
      });
    },
    // 获取 - 学员信息 - 下拉框出现/隐藏时触发
    clearUserSearchModel(e) {
      if (e) {
        this.userDatas.seaUserName = "";
        this.userDatas.seaUserIdcard = "";
        this.userDatas.seaUserMobile = "";
        this.superUserSelect();
      }
    },
    // 获取 - 获取字典
    getInvoice_type() {
      // 审核结果
      let arr1 = this.$setDictionary("CT_USER_AUDIT_STATE", "list");
      for (const key in arr1) {
        this.auditStateList.push({
          label: arr1[key],
          value: key
        });
      }
      // 报考进度 
      let arr2 = this.$setDictionary("CT_USER_APPLY_STATE", "list");
      for (const key in arr2) {
        this.applyStateList.push({
          label: arr2[key],
          value: key
        });
      }
      // // 培训等级
      // let arr3 = this.$setDictionary("ET_TRAIN_LEVEL", "list");
      // for (const key in arr3) {
      //   this.trainLevelList.push({
      //     label: arr3[key],
      //     value: key
      //   });
      // }
      // 缴费状态
      let arr4 = this.$setDictionary("CT_USER_PAYMENT_STATE", "list");
      for (const key in arr4) {
        this.paymentStateList.push({
          label: arr4[key],
          value: key
        });
      }
    },
    // 获取 - 培训工种
    searchCompOccupation() {
      this.$post('/biz/ct/occupation/select/pageList', {
        pageNum: 1,
        pageSize: 10,
        occupationName: ""
      }).then(ret => {
        this.occupationList = ret.data || []
      }).catch(err => {
        return;
      })
    },
    // 获取 - 培训老师
    getCtChannelSelectList() {
      this.$post('/biz/ct/channel/ctChannelSelectList', { contactUser: '' }).then(ret => {
        this.teacherIdList = ret.data || []
        this.teacherIdList.unshift({
          contactUser: "自招",
          channelId: -1000,
        });
      }).catch(err => {
        return;
      })
    },
    // 获取 - 列表
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.retrievalData.userName) {
        params.userName = this.retrievalData.userName;
      }
      if (this.retrievalData.mobile) {
        params.mobile = this.retrievalData.mobile;
      }
      if (this.retrievalData.idcard) {
        params.idcard = this.retrievalData.idcard;
      }
      if (this.retrievalData.auditState) {
        params.auditState = this.retrievalData.auditState;
      }
      if (this.retrievalData.applyState) {
        params.applyState = this.retrievalData.applyState;
      }
      if (this.retrievalData.occupationId) {
        params.occupationId = this.retrievalData.occupationId;
      }
      if (this.retrievalData.teacherId) {
        params.teacherId = this.retrievalData.teacherId;
      }
      if (this.retrievalData.paymentState) {
        params.paymentState = this.retrievalData.paymentState;
      }
      if (this.retrievalData.registrationTime !== null && this.retrievalData.registrationTime.length) {
        params.applyStartDate = this.retrievalData.registrationTime[0];
        params.applyEndDate = this.retrievalData.registrationTime[1];
      }
      this.doFetch({
        url: "/biz/ct/user/apply/schedule/pageList",
        params,
        pageNum,
      });
    },
    // 列表 - 勾选 
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 新增&编辑 - 弹框-打开
    setAddAndEdit(row) {
      this.cancelData();
      if (row?.projectApplyId) {// 编辑
        this.addAndEditData.projectApplyIdList.push(row.projectApplyId);
        this.addAndEditData.applyState = row.applyState;
      } else {
        if (this.multipleSelection.length) {
          for (let i = 0; i < this.multipleSelection.length; i++) {
            this.addAndEditData.projectApplyIdList.push(this.multipleSelection[i].projectApplyId);
          }
        } else {
          this.$message.warning("请至少勾选一项");
          return
        }
      }
      this.addAndEditData.dialogVisible = true;
    },
    // 新增&编辑 - 弹框-取消
    cancelData() {
      this.addAndEditData = this.$options.data().addAndEditData;
    },
    // 新增&编辑(缴费) - 弹框-取消(清空所有数据)用
    cancelDataAll() {
      this.addAndEditData = this.$options.data().addAndEditData;
      this.$refs.multipleTable.clearSelection();
    },
    // 新增&编辑 - 弹框-确认
    saveData() {
      this.$refs['addAndEditData'].validate((valid) => {
        if (valid) {
          this.$post("/biz/ct/user/apply/schedule/update", {
            projectApplyIdList: this.addAndEditData.projectApplyIdList,
            applyState: this.addAndEditData.applyState
          }).then((res) => {
            this.cancelDataAll();
            this.getData(this.pageNum);
            this.$message.success("操作成功");
          }).catch(() => {
            return;
          });
        }
      });
    },
  },
};
</script>
<style lang="less" scope></style>